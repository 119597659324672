<template>
    <div class="container-fluid">
        <!-- <div class="row opacity-100"><img alt="Jet Dock Wisconsin"  class="img-fluid p-2" :src="img1"></div> -->
        <div class="row bg-info text-start">
          <p class="text-white">
            JetDock systems make boating easier and more enjoyable with their ease of customization and operation. 
            A JetDock solution has many advantages over other lift options including their versatility, durability, boat accommodation, and safety. 
            Spend less time turning cranks and more time on the water with our simple and safe drive-on lift system. 
            JetDock owners estimate that they find themselves being able to use their watercraft up to 3 times more after purchase 
            due to the ease of use and ownership of our drive-on lift systems and floating dock solutions.
          </p>
        </div>
    <cform3/>
    </div>
</template>

<script>
import cform3 from './cform3.vue'
export default {
  name: "JetDockMainPage",
  data (){
      return {
          img1: "https://dsm01pap006files.storage.live.com/y4mbx8_3xkbG2XL3D4AI0kMymkJ5rk48Q26cOVOpETMzZHZCZtUiy8py-lEsFWURnPeKmvfqJudJsurUk8Sce2vJEcleUpihCPbQJj4X-g9_flTRBcnVuTp0V8QaBYzEj1cflMoMAb5XtcN4fUGkLuZGf8XNpE-Lcc5IBvq0ZEC6tXm7EbdqYcVspA1uk8DEYuQ?width=1432&height=358&cropmode=none"
      }
  },
  components: {
    cform3
  }
};
</script>