import { createRouter, createWebHashHistory } from 'vue-router'
import JetDockMainPage from '../views/jdwi.vue'

const routes = [
  {
    path: '/',
    name: 'JetDockMainPage',
    component: JetDockMainPage
  },
  {
    path: '/LocalInstalls',
    name: 'LocalInstalls',
    component: () => import('../views/localinstalls.vue')
  },
  {
    path: '/contactForm',
    name: 'contactForm',
    component: () => import('../views/cform3.vue')
  },
  {
    path: '/accessories',
    name: 'accessories',
    component: () => import('../views/accessories.vue')
  },
   {
    path: '/pwclifts',
    name: 'pwclifts',
    component: () => import('../views/pwclifts.vue')
    },
    {
      path: '/boatlifts',
      name: 'boatlifts',
      component: () => import('../views/boatlifts.vue')
      },
    {
      path: '/docks',
      name: 'docks',
      component: () => import('../views/docks.vue')
      },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // eslint-disable-next-line no-unused-vars
  // scrollBehavior (to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //       behavior: 'smooth',
  //     }
  //   }
  //},
  // eslint-disable-next-line no-unused-vars
     scrollBehavior (to, from, savedPosition) {
    return { top: 0}
  },
  routes
})

export default router
